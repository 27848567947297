import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet"; // Import Helmet for SEO
import './CategoryDetail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';

function CategoryDetail() {
    const { id: slug } = useParams();
    const navigate = useNavigate();

    const [metadata, setMetadata] = useState(null);
    const [category, setCategory] = useState(null);
    const [latestTable, setLatestTable] = useState(null);
    const [tables, setTables] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tablesLoading, setTablesLoading] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDiscordLinked, setIsDiscordLinked] = useState(false);
    const [notificationsEnabled, setNotificationsEnabled] = useState(false);
    const [userId, setUserId] = useState(null);
    const [reports, setReports] = useState({}); // Tracks report counts and input for each table
    const [reportInput, setReportInput] = useState(""); // Tracks the user's input for the report
    const [isReporting, setIsReporting] = useState(false); // Tracks the reporting state
    const [expandedReports, setExpandedReports] = useState({}); // Tracks expanded state for each table
    const [status, setStatus] = useState("green"); // Tracks the status of the category
    const [reportCount, setReportCount] = useState(0); // Tracks the report count
    const [isReportFormVisible, setIsReportFormVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    localStorage.setItem("redirectAfterLogin", window.location.pathname);
    const [isLatestTableSaved, setIsLatestTableSaved] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [feedbackType, setFeedbackType] = useState(""); // 'success' or 'error'

    
    const notificationIcon = !isDiscordLinked
    ? "https://sintrix.net/disclinknoti2.png" // Icon for unlinked or logged-out users
    : notificationsEnabled
    ? "https://sintrix.net/disclinknoti3.png" // Icon for linked Discord with notifications enabled
    : "https://sintrix.net/disclinknoti1.png"; // Icon for linked Discord with notifications disabled


    // Token Management
    const verifyToken = (token) => {
        const decoded = decodeToken(token);
        if (!decoded) return false;
    
        const currentTime = Math.floor(Date.now() / 1000);
        return decoded.exp > currentTime; // Valid if token expiration is in the future
    };
    

    const decodeToken = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (err) {
            console.error("Invalid token format:", err);
            return null;
        }
    };

    const fetchAccessTokenIfNeeded = async () => {
        const token = localStorage.getItem("token");
        if (!token) return null;
    
        if (!verifyToken(token)) {
            try {
                return await refreshAccessToken(); // Refresh if expired
            } catch (err) {
                console.error("Failed to refresh token:", err);
                return null;
            }
        }
        return token; // Return valid token
    };

    const refreshAccessToken = async () => {
        try {
            const refreshToken = localStorage.getItem("refreshToken");
            const response = await axios.post("/api/auth/refresh-token", { refreshToken });
            const { accessToken } = response.data;
    
            localStorage.setItem("token", accessToken);
            return accessToken;
        } catch (err) {
            console.error("Failed to refresh access token. Logging out...");
            
            handleLogout();
            return null;
        }
    };
    
    const clearFeedbackAfterDelay = (delay = 5000) => {
        setTimeout(() => {
            setFeedbackMessage("");
            setFeedbackType("");
        }, delay);
    };    

    const handleSaveOrUnsaveLatestTable = async () => {
        if (!latestTable) {
            setFeedbackType("error");
            setFeedbackMessage("No table available to save.");
            clearFeedbackAfterDelay();
            return;
        }
    
        try {
            const token = await fetchAccessTokenIfNeeded();
            if (!token) {
                setFeedbackType("error");
                setFeedbackMessage("You must be logged in to modify saved tables.");
                clearFeedbackAfterDelay();
                return;
            }
    
            if (isLatestTableSaved) {
                // Unsave logic
                await axios.post(
                    `/api/auth/categories/${category.slug}/unsave-latest-table`,
                    {},
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setIsLatestTableSaved(false);
                setFeedbackType("success");
                setFeedbackMessage("Table unsaved successfully.");
            } else {
                // Save logic
                await axios.post(
                    `/api/auth/categories/${category.slug}/save-latest-table`,
                    {},
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setIsLatestTableSaved(true);
                setFeedbackType("success");
                setFeedbackMessage("Table saved successfully.");
            }
        } catch (error) {
            console.error("Error modifying saved state of the table:", error.response?.data || error.message);
            setFeedbackType("error");
            setFeedbackMessage("Failed to update the saved state. Please try again.");
        } finally {
            clearFeedbackAfterDelay();
        }
    };        

    // Handler to toggle report form visibility
    const toggleReportForm = () => {
        setIsReportFormVisible((prev) => !prev);
        setErrorMessage(""); // Clear any existing errors when toggling the form
    };
    
    const handleReportCategory = async () => {
        try {
            const response = await axios.post(`/api/auth/categories/${slug}/report`);
            setReportCount(response.data.reportCount); // Update the report count
            setStatus(response.data.status); // Update the status
            alert("Report submitted successfully!");
        } catch (error) {
            console.error("Error reporting category:", error);
            alert("Failed to submit the report. Please try again.");
        }
    };

    
    useEffect(() => {
        const fetchSavedState = async () => {
            if (!latestTable || !category?.slug) return;
    
            try {
                const token = await fetchAccessTokenIfNeeded();
                if (!token) return;
    
                // Fetch the saved state of the latest table for the user
                const response = await axios.get(`/api/auth/categories/${category.slug}/saved-state`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
    
                // Check if the latest table is already saved
                setIsLatestTableSaved(response.data.isSaved);
            } catch (error) {
                console.error("Error checking saved state of the latest table:", error);
            }
        };
    
        fetchSavedState();
    }, [latestTable, category?.slug]);
    
    

    // Submit handler for the report form
    const handleReportSubmit = async () => {
        if (!reportInput.trim()) {
            setErrorMessage("Please enter a description of the issue.");
            return;
        }
    
        setIsReporting(true);
        setErrorMessage(""); // Clear any existing errors
    
        try {
            const token = await fetchAccessTokenIfNeeded();
            if (!token) {
                setErrorMessage("You must be logged in to submit a report.");
                setIsReporting(false);
                return;
            }
    
            const response = await axios.post(
                `/api/auth/categories/${slug}/report`,
                { description: reportInput },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            setReportCount((prev) => prev + 1);
            setReportInput("");
            setIsReportFormVisible(false);
    
            // Show success message
            setSuccessMessage("Report submitted successfully!");
            setTimeout(() => setSuccessMessage(""), 5000); // Clear message after 5 seconds
        } catch (error) {
            const errorMsg = error.response?.data?.message || "Failed to submit the report. Please try again.";
            setErrorMessage(errorMsg);
        } finally {
            setIsReporting(false);
        }
    };
    
    

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        navigate("/login");
    };

    // Fetch Discord connection status and notification preference
    const fetchDiscordStatus = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            return;
        }
    
        const validToken = await fetchAccessTokenIfNeeded();
        if (!validToken) return;
    
        try {
            const response = await axios.get("/api/auth/dashboard", {
                headers: { Authorization: `Bearer ${validToken}` },
            });
            setIsDiscordLinked(response.data.discordLinked);
            setUserId(response.data.userId);
        } catch (err) {
            console.error("Error fetching Discord status:", err);
        }
    };
    
    
    const fetchNotificationStatus = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            return;
        }
    
        try {
            const response = await axios.get(`/api/auth/notifications/${slug}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setNotificationsEnabled(response.data.enabled);
        } catch (error) {
            console.error("Error fetching notification status:", error);
            setNotificationsEnabled(false); // Default to disabled if the fetch fails
        }
    };
    

    const handleToggleNotifications = async () => {
        const token = await fetchAccessTokenIfNeeded();
        if (!token) {
            alert("You must be logged in to enable notifications.");
            return;
        }
    
        if (!isDiscordLinked) {
            alert("You must link your Discord account to enable notifications.");
            return;
        }
    
        try {
            const response = await axios.post(
                `/api/auth/notifications/${slug}`,
                { enabled: !notificationsEnabled },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setNotificationsEnabled(response.data.enabled);
            fetchNotificationStatus();
        } catch (error) {
            console.error("Error updating notification status:", error.response?.data || error.message);
            alert("Failed to update notifications. Please try again.");
        }
    };
    
    
    // Fetch Metadata
    const fetchMetadata = async () => {
        try {
            const response = await axios.get(`/metadata/${slug}`);
            setMetadata(response.data);
        } catch (error) {
            console.error("Error fetching metadata:", error);
        }
    };

    // Fetch Category Details
    const fetchCategoryDetails = async () => {
        try {
            const response = await axios.get(`/api/admin/categories/${slug}`);
            setCategory(response.data);
            setStatus(response.data.status || "green");
            setReportCount(response.data.reportCount || 0);
        } catch (error) {
            console.error("Error fetching category details:", error);
        } finally {
            setLoading(false);
        }
    };
    

    // Fetch Tables
    const fetchTables = async () => {
        setTablesLoading(true);
        try {
            const response = await axios.get(`/api/admin/tables/${slug}`);
            const sortedTables = response.data.sort((a, b) => {
                const versionA = a.version.split('.').map(Number);
                const versionB = b.version.split('.').map(Number);
                for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
                    const numA = versionA[i] || 0;
                    const numB = versionB[i] || 0;
                    if (numA !== numB) {
                        return numB - numA;
                    }
                }
                return 0;
            });
    
            setTables(sortedTables);
    
            // Initialize the reports state
            const initialReports = {};
            sortedTables.forEach((table) => {
                initialReports[table._id] = { count: table.reportCount || 0, input: "" };
            });
            setReports(initialReports);
    
            // Set the latest table based on the response (first table after sorting)
            if (sortedTables.length > 0) {
                setLatestTable(sortedTables[0]);
            }
        } catch (error) {
            console.error("Error fetching tables:", error);
        } finally {
            setTablesLoading(false);
        }
    };
    
    

    useEffect(() => {
        fetchMetadata();
        fetchCategoryDetails();
        fetchTables();
        // These depend on authentication, so call them conditionally
        fetchDiscordStatus();
        fetchNotificationStatus();
    }, [slug]);
    

    // Handle Table Download
    const handleTableDownload = async (table) => {
        try {
            const response = await axios.get(`/api/admin/download-table/${table._id}`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${category.slug}-v${table.version}.CT`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            fetchTables(); // Fetch updated tables to reflect the download count
        } catch (error) {
            console.error("Error downloading table:", error);
        }
    };

    // UI Logic
    const toggleShowMore = () => setShowMore(!showMore);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % category.images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + category.images.length) % category.images.length);
    };

    const handleExpandImage = () => {
        setIsExpanded(true);
    };

    const closeExpandImage = () => {
        setIsExpanded(false);
    };

    const formatYouTubeEmbedUrl = (url) => {
        const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : url;
    };

    if (loading) {
        return <p>Loading game details...</p>;
    }

    return (
        <div className="category-detail-container">
            {/* Dynamic SEO with Helmet */}
            <Helmet>
                <title>{metadata?.title || "Loading..."}</title>
                <meta name="description" content={metadata?.description || "Loading..."} />
                <meta property="og:title" content={metadata?.title || "Loading..."} />
                <meta property="og:description" content={metadata?.description || "Loading..."} />
                <meta property="og:image" content={metadata?.image || "https://sintrix.net/sintrix2.png"} />
                <meta property="og:url" content={metadata?.url || "https://sintrix.net"} />
            </Helmet>

            <h2 className="category-title">{category.name}</h2>

            {/* Display message if the game is discontinued */}
            {category.discontinued && (
                <p className="discontinued-message">This game is discontinued and will not be receiving updates.</p>
            )}

            {/* Display Feedback Messages */}
            {feedbackMessage && (
                <div className={`alert ${feedbackType === "error" ? "alert-error" : "alert-success"}`}>
                    <span className="alert-icon">
                        {feedbackType === "error" ? "❌" : "✅"}
                    </span>
                    <span className="alert-message">{feedbackMessage}</span>
                    <button
                        className="alert-close"
                        onClick={() => setFeedbackMessage("")}
                        aria-label="Close notification"
                    >
                        &times;
                    </button>
                </div>
            )}

            {/* Display Error and Success Messages for Reports */}
            {errorMessage && (
                <div className="alert alert-error">
                    <span className="alert-icon">❌</span>
                    <span className="alert-message">{errorMessage}</span>
                    <button
                        className="alert-close"
                        onClick={() => setErrorMessage("")}
                        aria-label="Close error"
                    >
                        &times;
                    </button>
                </div>
            )}

            {successMessage && (
                <div className="alert alert-success">
                    <span className="alert-icon">✅</span>
                    <span className="alert-message">{successMessage}</span>
                    <button
                        className="alert-close"
                        onClick={() => setSuccessMessage("")}
                        aria-label="Close success"
                    >
                        &times;
                    </button>
                </div>
            )}

            <div className="category-content">
                {/* Left Side: Image showcase and videos */}
                <div className="category-media">
                    {/* Image Slideshow */}
                    <div className="image-container">
                        {/* Status Badge */}
                        <div className={`status-badge-overlay ${status}`}>
                            {status === "green" && (
                                <>
                                    <span className="status-badge-icon">✔</span> Working
                                </>
                            )}
                            {status === "yellow" && (
                                <>
                                    <span className="status-badge-icon">⚠</span> Possible Issues
                                </>
                            )}
                            {status === "red" && (
                                <>
                                    <span className="status-badge-icon">✖</span> Broken
                                </>
                            )}
                        </div>

                        {/* Image Slideshow */}
                        <div className="image-slideshow">
                            <img
                                src={category.images[currentImageIndex]}
                                alt={`Screenshot ${currentImageIndex + 1}`}
                                className="category-screenshot"
                                onClick={handleExpandImage}
                            />
                            <button className="prev-btn" onClick={prevImage}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>
                            <button className="next-btn" onClick={nextImage}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </div>
                    </div>


                    {/* Expanded Image View */}
                    {isExpanded && (
                        <div className="expanded-image-overlay" onClick={closeExpandImage}>
                            <div className="expanded-image-container">
                                <img
                                    src={category.images[currentImageIndex]}
                                    alt={`Expanded Screenshot ${currentImageIndex + 1}`}
                                    className="expanded-image"
                                />
                                <button className="close-btn" onClick={closeExpandImage}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                        </div>
                    )}

                    {/* Video section */}
                    <div className="video-showcase">
                        {category.video ? (
                            <iframe
                                width="420"
                                height="315"
                                src={formatYouTubeEmbedUrl(category.video)}
                                title="Video Showcase"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <p>No video available.</p>
                        )}
                    </div>
                </div>

                {/* Right Side: Description and Downloads */}
                <div className="category-description">

                    <div className="notification-section">
                        {/* Discord Notifications Toggle */}
                        <div
                            className="notification-toggle"
                            data-tooltip={
                                !isDiscordLinked
                                    ? "You need to login or link your Discord account to enable notifications."
                                    : notificationsEnabled
                                    ? "Disable Discord notifications for this game."
                                    : "Enable Discord notifications for this game."
                            }
                            onClick={isDiscordLinked ? handleToggleNotifications : null}
                        >
                            <img
                                src={notificationIcon}
                                alt="Notification Icon"
                                className={`notification-icon ${!isDiscordLinked ? "disabled" : ""}`}
                            />
                        </div>

                        {/* Save Latest Table Button */}
                            <div
                                className={`save-latest-button-container ${
                                    !localStorage.getItem("token") || !latestTable ? "disabled" : ""
                                }`}
                                onClick={
                                    !localStorage.getItem("token")
                                        ? () => setFeedbackMessage("You must log in to save tables.") // Inline feedback for logged-out users
                                        : !latestTable
                                        ? null // No action if no table is available
                                        : handleSaveOrUnsaveLatestTable // Call save/unsave handler
                                }
                                data-tooltip={
                                    !localStorage.getItem("token")
                                        ? "You must log in to save tables."
                                        : !latestTable
                                        ? "No table available to save."
                                        : isLatestTableSaved
                                        ? "Click to unsave this table."
                                        : "Click to save this table."
                                }
                            >
                                {latestTable && (
                                    <img
                                        src={
                                            !localStorage.getItem("token")
                                                ? "https://sintrix.net/savetable2.png" // Icon for logged-out users
                                                : isLatestTableSaved
                                                ? "https://sintrix.net/savetable3.png" // Icon for "unsave"
                                                : "https://sintrix.net/savetable1.png" // Icon for "save"
                                        }
                                        alt={
                                            !localStorage.getItem("token")
                                                ? "Login to Save Icon"
                                                : isLatestTableSaved
                                                ? "Unsave Icon"
                                                : "Save Icon"
                                        }
                                        className={`save-latest-icon ${isLatestTableSaved ? "saved" : ""}`}
                                    />
                                )}
                            </div>
                    </div>

                    {/* Description Section */}
                    <div>
                        {latestTable ? (
                            <>
                                {/* Description */}
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: latestTable.description.replace(/\n/g, "<br />"),
                                    }}
                                />
                                {/* Latest Table Upload Date */}
                                <p className="latest-table-date">
                                    <strong>Latest Table Uploaded:</strong>{" "}
                                    {new Date(latestTable.createdAt).toLocaleDateString()} {/* Format the date */}
                                </p>
                            </>
                        ) : (
                            <p>No description or upload date available for the latest table.</p>
                        )}
                    </div>

                    {/* Steam Link */}
                    {category?.appid && (
                        <p>
                            <strong>Steam Link:</strong>{" "}
                            <a  className="steam-link"
                                href={`https://store.steampowered.com/app/${category.appid}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {category.name}
                            </a>
                        </p>
                    )}


                    {/* Report Button and Form */}
                    <div className="action-buttons">
                        <button onClick={toggleReportForm} className="report-button">
                            {isReportFormVisible ? "Cancel" : "Report Issue"}
                        </button>

                        {isReportFormVisible && (
                            <div className="report-form">
                                <textarea
                                    className="report-textbox"
                                    placeholder="Describe the issue..."
                                    value={reportInput}
                                    onChange={(e) => setReportInput(e.target.value)}
                                    rows="4"
                                />
                                <button
                                    className="report-submit-button"
                                    onClick={handleReportSubmit}
                                    disabled={isReporting}
                                >
                                    {isReporting ? "Submitting..." : "Submit Report"}
                                </button>
                            </div>
                        )}
                    </div>

                    {tablesLoading ? (
                        <p>Loading tables...</p>
                    ) : (
                        <>
                            <h3>Available Tables</h3>
                            {tables.length === 0 ? (
                                <p>No tables available for this game.</p>
                            ) : (
                                <>
                                    {tables.slice(0, showMore ? tables.length : 1).map((table) => (
                                        <div key={table._id} className="table-item">
                                            <p>Version: {table.version}</p>
                                            <p>Downloads: {table.downloadCount || 0}</p>
                                            <button
                                                onClick={() => handleTableDownload(table)}
                                                className="download-link"
                                            >
                                                Download v{table.version}
                                            </button>
                                        </div>
                                    ))}
                                    {tables.length > 1 && (
                                        <a href="#!" onClick={toggleShowMore} className="show-more-link">
                                            {showMore ? (
                                                <FontAwesomeIcon icon={faChevronUp} />
                                            ) : (
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            )}
                                        </a>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>


            </div>

            {/* Disclaimer Section */}
            <div className="disclaimer">
                <p>
                    Disclaimer: We are not responsible for any loss of account, data, or damage resulting from
                    the use of these tables. Use at your own risk.
                </p>
            </div>
        </div>
    );
}

export default CategoryDetail;
