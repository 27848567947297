import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import AdminPanel from "./AdminPanel";
import ModeratorPanel from "./ModeratorPanel";
import Login from "./components/Login";
import Signup from "./components/Signup";
import CheatRequests from "./components/CheatRequests";
import UserDashboard from "./components/UserDashboard";
import PublicProfile from './components/PublicProfile';
import Tables from "./components/Games";
import Logout from "./components/Logout";
import Navbar from "./components/Navbar";
import CategoryDetail from "./components/CategoryDetail";
import DiscordButton from './components/DiscordButton';
import AppPanel from "./components/AppPanel";
import ErrorPage from "./components/ErrorPage";
import Credits from "./components/Credits";
import Support from "./components/Support";
import ToolsPage from "./components/ToolsPage";
import VerifyEmail from "./components/VerifyEmail";
import ForgotPass from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import './App.css';

function App() {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        return localStorage.getItem("theme") === "dark";
    });

    // Toggle theme effect
    useEffect(() => {
        document.body.className = isDarkMode ? "dark" : "light";
        localStorage.setItem("theme", isDarkMode ? "dark" : "light");
    }, [isDarkMode]);

    const toggleTheme = () => setIsDarkMode((prevMode) => !prevMode);

    // Axios interceptor effect
    useEffect(() => {
        
        const interceptor = axios.interceptors.response.use(
            (response) => response,
            (error) => {
                const isLoginError = error.config.url.includes("/api/auth/login");
                if (error.response && error.response.status === 403 && !isLoginError) {
                    handleLogout();
                }
                return Promise.reject(error);
            }
            
        );

        return () => axios.interceptors.response.eject(interceptor);
    }, []);

    // Logout handler
    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login";
    };

    // Clear default head metadata so we can use our own on each page
    useEffect(() => {
        const headTags = document.querySelectorAll('head meta[name="description"], head meta[property^="og:"], head title');
        headTags.forEach(tag => {
            if (tag.getAttribute('data-react-helmet') === null) {
                tag.parentNode.removeChild(tag);
            }
        });
    }, []);
    

    // Console welcome message effect
    useEffect(() => {
        console.log(
            `%cWelcome to Sintrix 🎉\n\n\n\n` +
            `%cHappy hacking! 🚀\n\n\n\n`,
            "color: #8319d4; font-size: 20px; font-weight: bold; text-shadow: 2px 2px #6a0dab;", 
            "font-size: 15px; font-weight: bold; text-shadow: 2px 2px #6a0dab;"
        );
    }, []);

    return (
        <Router>
            <div>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/verify-email" element={<VerifyEmail />} /> 
                    <Route path="/login" element={<Login />} /> 
                    <Route path="/forgot-password" element={<ForgotPass />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/requests" element={<CheatRequests />} /> 
                    <Route path="/games" element={<Tables />} />
                    <Route path="/tools" element={<ToolsPage />} />
                    <Route path="/dashboard" element={<UserDashboard />} />
                    <Route path="/profile/:username" element={<PublicProfile />} />  
                    <Route path="/admin" element={<AdminPanel />} />
                    <Route path="/mod" element={<ModeratorPanel />} />
                    <Route path="/app" element={<AppPanel />} />
                    <Route path="/logout" element={<Logout />} /> 
                    <Route path="/game/:id" element={<CategoryDetail />} />
                    <Route path="/error" element={<ErrorPage />} />
                    <Route path="/redpill" element={<Credits />} />
                    <Route path="/support" element={<Support />} />
                </Routes>
                <DiscordButton />
            </div>
        </Router>
    );
}

export default App;
