import React, { useState, useEffect } from 'react';
import axios from 'axios';

const versionsApiUrl = 'https://sintrix.net/api/admin/versions'; // Endpoint to fetch all versions
const versionApiUrl = 'https://sintrix.net/api/admin/version'; // Endpoint to add/edit/delete a version

const AppPanel = () => {
  const [versions, setVersions] = useState([]);
  const [newVersion, setNewVersion] = useState({
    version: '',
    downloadUrl: '',
    description: '',
  });
  const [editingVersion, setEditingVersion] = useState(null); // Track the version being edited
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const token = localStorage.getItem('token'); // Get the token from localStorage

  useEffect(() => {
    fetchVersions();
  }, []);

  // Fetch all versions
  const fetchVersions = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(versionsApiUrl, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in headers
        },
      });
      setVersions(response.data); // Assume data is an array of version objects
    } catch (err) {
      setError('Error fetching versions. Please try again later.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Add or Update a version
  const saveVersion = async () => {
    const { version, downloadUrl, description } = newVersion;

    if (!version || !downloadUrl) {
      alert('Version and Download URL are required.');
      return;
    }

    try {
      if (editingVersion) {
        // Update existing version
        await axios.put(
          `${versionApiUrl}/${editingVersion._id}`,
          { version, downloadUrl, description },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`, // Include the token in headers
            },
          }
        );
        alert('Version updated successfully!');
      } else {
        // Add new version
        await axios.post(
          versionApiUrl,
          { version, downloadUrl, description },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`, // Include the token in headers
            },
          }
        );
        alert('Version added successfully!');
      }

      setNewVersion({ version: '', downloadUrl: '', description: '' });
      setEditingVersion(null); // Clear editing state
      fetchVersions(); // Refresh version list
    } catch (err) {
      alert('Error saving version. Please try again.');
      console.error(err);
    }
  };

  // Delete a version
  const deleteVersion = async (id) => {
    if (!window.confirm('Are you sure you want to delete this version?')) return;

    try {
      await axios.delete(`${versionApiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in headers
        },
      });
      alert('Version deleted successfully!');
      fetchVersions(); // Refresh version list
    } catch (err) {
      alert('Error deleting version. Please try again.');
      console.error(err);
    }
  };

  // Handle Edit Click
  const handleEditClick = (version) => {
    setEditingVersion(version); // Set the version being edited
    setNewVersion({
      version: version.version,
      downloadUrl: version.downloadUrl,
      description: version.description,
    });
  };

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: 'var(--background-color)',
        color: 'var(--text-color)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h1 style={{ color: 'var(--text-color)' }}>Table Finder - Management</h1>

      {/* Error Handling */}
      {error && <div style={{ color: 'var(--error-color)', marginBottom: '20px' }}>{error}</div>}

      {/* Loading State */}
      {loading && <div style={{ marginBottom: '20px' }}>Loading versions...</div>}

      {/* Version List */}
      {!loading && versions.length > 0 && (
        <div
          style={{
            marginBottom: '30px',
            backgroundColor: 'var(--card-background)',
            padding: '20px',
            borderRadius: '8px',
            width: '80%',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <h2 style={{ borderBottom: `1px solid var(--border-color)`, paddingBottom: '10px' }}>
            Existing Versions
          </h2>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {versions.map((version) => (
              <li
                key={version._id}
                style={{
                  padding: '10px 0',
                  fontSize: '16px',
                  borderBottom: `1px solid var(--border-color)`,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>Version: {version.version}</span>
                <div>
                  <button
                    onClick={() => handleEditClick(version)}
                    style={{
                      marginRight: '10px',
                      padding: '5px 10px',
                      backgroundColor: 'var(--button-background)',
                      color: 'var(--button-text)',
                      border: 'none',
                      cursor: 'pointer',
                      borderRadius: '4px',
                    }}
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => deleteVersion(version._id)}
                    style={{
                      padding: '5px 10px',
                      backgroundColor: 'var(--error-color)',
                      color: 'var(--button-text)',
                      border: 'none',
                      cursor: 'pointer',
                      borderRadius: '4px',
                    }}
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Add/Edit Version */}
      <div
        style={{
          marginBottom: '30px',
          backgroundColor: 'var(--section-background)',
          padding: '20px',
          borderRadius: '8px',
          width: '80%',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h2 style={{ borderBottom: `1px solid var(--border-color)`, paddingBottom: '10px' }}>
          {editingVersion ? 'Edit Version' : 'Add New Version'}
        </h2>
        <div style={{ marginBottom: '10px' }}>
          <label>Version: </label>
          <input
            type="text"
            value={newVersion.version}
            onChange={(e) => setNewVersion({ ...newVersion, version: e.target.value })}
            placeholder="e.g., 1.2.3"
            style={{
              padding: '5px',
              marginLeft: '10px',
              backgroundColor: 'var(--input-background)',
              color: 'var(--input-text)',
              border: `1px solid var(--border-color)`,
              borderRadius: '4px',
            }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>Download URL: </label>
          <input
            type="text"
            value={newVersion.downloadUrl}
            onChange={(e) => setNewVersion({ ...newVersion, downloadUrl: e.target.value })}
            placeholder="e.g., https://example.com/download"
            style={{
              padding: '5px',
              marginLeft: '10px',
              backgroundColor: 'var(--input-background)',
              color: 'var(--input-text)',
              border: `1px solid var(--border-color)`,
              borderRadius: '4px',
            }}
          />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <label>Description: </label>
          <textarea
            value={newVersion.description}
            onChange={(e) => setNewVersion({ ...newVersion, description: e.target.value })}
            placeholder="Optional description..."
            style={{
              padding: '5px',
              width: '100%',
              height: '100px',
              marginTop: '5px',
              backgroundColor: 'var(--input-background)',
              color: 'var(--input-text)',
              border: `1px solid var(--border-color)`,
              borderRadius: '4px',
            }}
          ></textarea>
        </div>
        <div style={{ textAlign: 'center' }}>
          <button
            onClick={saveVersion}
            style={{
              padding: '10px 20px',
              backgroundColor: 'var(--button-background)',
              color: 'var(--button-text)',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '4px',
              marginRight: '10px',
            }}
          >
            {editingVersion ? 'Update Version' : 'Add Version'}
          </button>
          {editingVersion && (
            <button
              onClick={() => {
                setEditingVersion(null);
                setNewVersion({ version: '', downloadUrl: '', description: '' });
              }}
              style={{
                padding: '10px 20px',
                backgroundColor: 'var(--hover-background)',
                color: 'var(--button-text)',
                border: 'none',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppPanel;
