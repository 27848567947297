import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ToolsPage.css";

const toolsApiUrl = "https://sintrix.net/api/admin/tools";

const ToolsPage = () => {
  const [tools, setTools] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchTools();
  }, []);

  const fetchTools = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(toolsApiUrl);
      setTools(response.data);
    } catch (err) {
      setError("Error fetching tools. Please try again later.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const groupToolsByCategory = () => {
    return tools.reduce((categories, tool) => {
      const category = tool.category || "Uncategorized";
      if (!categories[category]) {
        categories[category] = [];
      }
      categories[category].push(tool);
      return categories;
    }, {});
  };

  const groupedTools = groupToolsByCategory();

  return (
    <div className="tools-page">
      <header className="tools-header">
        <h1 className="tools-title">Tools Hub</h1>
        <p className="tools-subtitle">
          Explore helpful tools for all your cheating needs.
        </p>
      </header>

      {error && <div className="tools-error">{error}</div>}
      {loading && <div className="tools-loading">Loading tools...</div>}

      {!loading && tools.length > 0 && (
        <div className="tools-container">
          {Object.entries(groupedTools).map(([category, categoryTools]) => (
            <section key={category} className="tools-category">
              <h2 className="tools-category-title">{category}</h2>
              <div className="tools-grid">
                {categoryTools.map((tool) => (
                  <div key={tool._id} className="tool-card">
                    <h3 className="tool-title">{tool.name}</h3>
                    <p className="tool-description">{tool.description}</p>
                    <a
                      href={tool.downloadUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tool-download-button"
                    >
                      Download
                    </a>
                  </div>
                ))}
              </div>
            </section>
          ))}
        </div>
      )}

      {!loading && tools.length === 0 && (
        <div className="tools-empty">
          <p>No tools are currently available.</p>
        </div>
      )}
    </div>
  );
};

export default ToolsPage;
