import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./PublicProfile.css";

// Set up Axios interceptor for adding token to requests
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

const BADGE_ICONS = {
    credits_badge: { icon: "🐇", description: "Found the hidden credits page" },
    early_bird: { icon: "🐤", description: "Awarded to the first 100 users."} ,
    top_contributor: { icon: "🌟",  description: "Awarded to users who submit 10 or more requests."},
    keyboard_warrior: { icon: "⌨️",  description: "Awarded to users who send 100 or more messages."},
};



const PublicProfile = () => {
    const { username } = useParams();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeBadge, setActiveBadge] = useState(null);
    localStorage.setItem("redirectAfterLogin", window.location.pathname);
    const [metadata, setMetadata] = useState({
        title: "Profile - Sintrix.net",
        description: "Explore profiles on Sintrix.net.",
        image: "https://sintrix.net/default-avatar.png",
        url: "",
    });
    const [notifications, setNotifications] = useState([]);
    const [messageContent, setMessageContent] = useState("");
    const [messageError, setMessageError] = useState("");

    // Visibility states for toggling sections
    const [showRequests, setShowRequests] = useState(false);
    const [showVotes, setShowVotes] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [reportReason, setReportReason] = useState("inappropriate");
    const [customReason, setCustomReason] = useState("");
    const REASONS = ["inappropriate", "advertising", "spam", "harassment", "impersonation", "other"];

    const getAvatarUrl = (avatar, username = "User") =>
        avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(username)}&background=random&bold=true`;

    const addNotification = (type, text) => {
        setNotifications((prev) => [...prev, { type, text }]);
        setTimeout(() => {
            setNotifications((prev) => prev.slice(1));
        }, 5000);
    };

    const isMobile = window.matchMedia("(max-width: 480px)").matches;

    const toggleTooltip = (badge) => {
        if (isMobile) {
            setActiveBadge((prev) => (prev === badge ? null : badge));
        }
    };

    const handleReportUser = async () => {
        const reasonToSend = reportReason === "other" ? customReason : reportReason;
    
        if (!reasonToSend.trim()) {
            addNotification("error", "Please provide a valid reason for reporting.");
            return;
        }
    
        if (!user || !user.username) {
            addNotification("error", "Unable to report user. Invalid user data.");
            return;
        }
    
        try {
            console.log("Report Payload:", {
                reportedUsername: user.username,
                reason: reasonToSend,
                description: customReason || "", // Send an empty string if not provided
            });
    
            await axios.post("/api/auth/report-user", {
                reportedUsername: user.username,
                reason: reasonToSend,
                description: customReason || "", // Backend expects description
            });
    
            addNotification("success", "User reported successfully!");
            setShowReportModal(false);
            setCustomReason("");
        } catch (err) {
            console.error("Error reporting user:", err.response?.data || err.message);
            addNotification("error", err.response?.data?.message || "Failed to report user.");
        }
    };
    
    

    const handleSendMessage = async () => {
        try {
            if (!messageContent.trim()) {
                setMessageError("Message cannot be empty.");
                return;
            }

            await axios.post("/api/auth/messages", {
                recipientUsername: user.username,
                content: messageContent.trim(),
            });

            addNotification("success", "Message sent successfully!");
            setMessageContent("");
        } catch (err) {
            console.error("Error sending message:", err);

            // Handle specific status codes
            if (err.response?.status === 400 && err.response?.data?.message === "This user has messaging disabled.") {
                addNotification("error", "This user has messaging disabled.");
            } else if (err.response?.status === 401) {
                addNotification("error", "Your session has expired. Please log in again.");
                // Optional: Redirect to login if necessary
                // window.location.href = "/login";
            } else {
                addNotification("error", err.response?.data?.message || "Failed to send message.");
            }
        }
    };

    

    useEffect(() => {
        setMetadata((prev) => ({
            ...prev,
            url: `https://sintrix.net/profile/${username}`,
        }));

        const fetchProfile = async () => {
            try {
                const [metadataResponse, profileResponse] = await Promise.all([
                    axios.get(`/metadata/profile/${username}`),
                    axios.get(`/api/requests/profile/${username}`),
                ]);

                setMetadata(metadataResponse.data);
                setUser(profileResponse.data); // Ensure badges are part of the user data
            } catch (err) {
                console.error("Error fetching profile or metadata:", err.response || err.message);
                setError(err.response?.data?.message || "Error fetching profile");
            } finally {
                setLoading(false);
            }
        };

        fetchProfile();
    }, [username]);

    if (loading) {
        return <p>Loading profile...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    if (!user) {
        return <p className="error">User data is unavailable.</p>;
    }

    return (
        <div className="public-profile">
            {/* Dynamic SEO with Helmet */}
            <Helmet>
                <title>{metadata?.title || "Profile - Sintrix.net"}</title>
                <meta name="description" content={metadata?.description || "Explore profiles on Sintrix.net."} />
                <meta property="og:title" content={metadata?.title || "Profile - Sintrix.net"} />
                <meta property="og:description" content={metadata?.description || "Explore profiles on Sintrix.net."} />
                <meta property="og:image" content={metadata?.image || "https://sintrix.net/default-avatar.png"} />
                <meta property="og:url" content={metadata?.url || `https://sintrix.net/profile/${username}`} />
            </Helmet>

            {/* Notifications */}
            <div className="notifications">
                {notifications.map((notification, index) => (
                    <div key={index} className={`notification ${notification.type}`}>
                        {notification.text}
                    </div>
                ))}
            </div>

            <div className="profile-header">
                <img
                    src={getAvatarUrl(user.avatar, user.username)}
                    alt={`${user.username}'s avatar`}
                    className="profile-avatar"
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(user.username)}&background=random&bold=true`;
                    }}
                />
                <h1>{user.username}</h1>
                <div className="profile-role-and-badges">
                    <span className={`role-badge ${user.role || "default"}`}>{user.role || "User"}</span>
                    <div className="badge-icons">
                        {user.badges &&
                            user.badges.map((badge) => (
                                <div
                                    key={badge}
                                    className={`badge-icon-wrapper ${activeBadge === badge ? "active" : ""}`}
                                    onClick={() => toggleTooltip(badge)}
                                >
                                    <span
                                        className="badge-icon"
                                        role="img"
                                        aria-label={BADGE_ICONS[badge]?.description}
                                    >
                                        {BADGE_ICONS[badge]?.icon || "🏅"}
                                    </span>
                                    <div className="btooltip">{BADGE_ICONS[badge]?.description}</div>
                                </div>
                            ))}
                    </div>
                </div>
                <button className="report-button" onClick={() => setShowReportModal(true)}>
                    Report User
                </button>
                {/* About Me Section */}
                <div className="about-me-section-p">
                    <h2 className="about-me-title-p">About Me</h2>
                    {user.aboutMe ? (
                        <p className="about-me-content-p">{user.aboutMe}</p>
                    ) : (
                        <p className="about-me-content-p">This user has not set up an About me section yet.</p>
                    )}
                </div>        
                <p className="profile-joined">
                    Joined: {user.createdAt ? new Date(user.createdAt).toLocaleDateString() : "Date not available"}
                </p>
                <div className="profile-actions">
                    <textarea
                        value={messageContent}
                        onChange={(e) => setMessageContent(e.target.value)}
                        placeholder="Write your message here..."
                        className="message-box"
                    />
                    {messageError && <p className="error">{messageError}</p>}
                    <button onClick={handleSendMessage}>Send Message</button>
                </div>
            </div>

            {showReportModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h2>Report User</h2>
                        <select
                            id="reason"
                            value={reportReason}
                            onChange={(e) => setReportReason(e.target.value)}
                        >
                            {REASONS.map((reason) => (
                                <option key={reason} value={reason}>
                                    {reason.charAt(0).toUpperCase() + reason.slice(1)}
                                </option>
                            ))}
                        </select>

                        {reportReason === "other" && (
                            <textarea
                                placeholder="Please describe the issue..."
                                value={customReason}
                                onChange={(e) => setCustomReason(e.target.value)}
                                rows="4"
                            ></textarea>
                        )}

                        <div className="modal-actions">
                            <button onClick={handleReportUser}>Submit Report</button>
                            <button onClick={() => setShowReportModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Toggle Buttons for Sections */}
            <div className="profile-section">
                <button className="toggle-button" onClick={() => setShowRequests(!showRequests)}>
                    {showRequests ? "Hide Submitted Requests" : "Show Submitted Requests"}
                </button>

                {showRequests && (
                    <>
                        <h2>Submitted Requests</h2>
                        {/* Check if submittedRequests are not available due to showRequests being disabled */}
                        {user.submittedRequests && user.submittedRequests.length > 0 ? (
                            <ul className="request-list">
                                {user.submittedRequests.map((request) => (
                                    <li key={request._id} className="request-item">
                                        <h3>{request.name}</h3>
                                        <p>Status: {request.status}</p>
                                        <p>Votes: {request.votes}</p>
                                        <p>Submitted: {new Date(request.createdAt).toLocaleDateString()}</p>
                                    </li>
                                ))}
                            </ul>
                        ) : user.showRequests === false ? (
                            <p>This user has requests visibility disabled.</p>
                        ) : (
                            <p>No requests submitted.</p>
                        )}
                    </>
                )}
            </div>

            <div className="profile-section">
                <button className="toggle-button" onClick={() => setShowVotes(!showVotes)}>
                    {showVotes ? "Hide Votes" : "Show Votes"}
                </button>

                {showVotes && (
                    <>
                        <h2>Votes</h2>
                        {/* Check if votes are not available due to showVotes being disabled */}
                        {user.votes && user.votes.length > 0 ? (
                            <ul className="vote-list">
                                {user.votes.map((vote) => (
                                    <li key={vote._id} className="vote-item">
                                        <h3>{vote.name}</h3>
                                        <p>Status: {vote.status}</p>
                                        <p>Votes: {vote.votes}</p>
                                    </li>
                                ))}
                            </ul>
                        ) : user.showVotes === false ? (
                            <p>This user has votes visibility disabled.</p>
                        ) : (
                            <p>No votes casted.</p>
                        )}
                    </>
                )}
            </div>

        </div>
    );
};

export default PublicProfile;
